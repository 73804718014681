@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap");

* {
  font-family: "Montserrat", Helvetica, sans-serif;
}

._body {
  min-height: 100vh;
}

.header {
  height: 80px;
}

.body {
  background-color: white;
  min-height: 100vh;
  font-family: "Montserrat", Helvetica, sans-serif;
}

h2 {
  font-size: 24px !important;
}

#login {
  background-image: url("images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
}

#login .card {
  border: none;
  border-radius: none;
}

#login .card-body {
  background-color: #f2f2f2;
  padding: 20px;
}

#login .card-header {
  background-color: #fe9015;
  padding: 20px;
}

span.firebaseui-idp-text {
  font-family: "Roboto", arial, sans-serif !important;
}

/* override */
.tab-content {
  flex-grow: 1;
  overflow-y: auto;
  /* height: 0px; */
  /* background-color: #f2f2f2; */
}

/* @media screen and (min-height: 750px) {
  .tab-content {
    height: 0px;
  }
} */

@media (max-width: 991px) {
  /* Styles for viewports up to 991px */
  .nav-tabs .nav-link.active {
    border-color: transparent !important;
    border-radius: 0;
    background-color: #f2f2f2 !important;
  }
}

.footer {
  font-size: 14px;
  background-color: #f2f2f2;
}

.footer a {
  text-decoration: none;
  color: #212529;
}

.footer a:hover {
  text-decoration: underline;
  color: #212529;
}

/* .tabs > li {
  background-color: #f2f2f2;
} */

.nav-item > button.nav-link {
  color: #000;
}

.nav-item > button.nav-link.active {
  color: #fe9015;
  /* background-color: #f2f2f2; */
}

/* .nav.nav-tabs {
  border-style: none !important;
}

.tabs > li > button {
  font-weight: bold;
  color: black;
  border-style: solid !important;
  border-width: 0px 2px 5px 2px !important;
  border-color: white white #f2f2f2 white !important;
  padding-left: 6px m !important;
}

.tabs > li .nav-link.active {
  color: #fe9015;
  border-color: white white #fe9015 white !important;
}

.tabs > li > button:hover {
  color: #999;
  background-color: white;
  border-color: white white #ccc white !important;
} */

.formStyle > div .form-label {
  font-weight: 700;
  color: #929191;
  margin-bottom: 3px;
}

.formStyle > div .form-control,
.formStyle > div .form-select {
  border: none;
  border-radius: 0;
  padding-bottom: 13px;
  padding-top: 13px;
}

.formStyle > div {
  margin-bottom: 1rem;
}
.formStyle > div.row {
  margin-bottom: 0;
}

.status {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 13px;
  padding-top: 13px;
}

.formStyle .col {
  margin-bottom: 18px;
}

.formStyle .form-check-input {
  font-size: 18px;
}

.formStyle .form-check-label {
  margin-top: 4px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.smallGreyFont {
  font-size: 14px;
  color: #929191;
  font-weight: 600;
}

.form-check-input:checked {
  /* border-color: #fe9015 !important; */
  background-color: #fe9015 !important;
  border: none !important;
}

.form-check-input:focus {
  border: none !important;
  box-shadow: none !important;
}

.form-control:disabled,
.form-select:disabled {
  background-color: white !important;
  color: #929191;
}

.form-control:focus,
.form-select:focus {
  /* border-color: #c1c1c1 !important; */
  box-shadow: -3px 3px #c1c1c1, 3px -3px #c1c1c1, 3px 3px #c1c1c1,
    -3px -3px #c1c1c1 !important;
}

.vehicleHeading {
  color: #fe9015;
  font-size: 14px;
  padding-top: 4px;
}

.biggerBlackText {
  color: black;
  font-size: 18px;
}

.modalVehicleEditForm {
  width: 370px !important;
}

.modalVehicleEditForm .modal-content {
  border-radius: 0px;
  background-color: #f2f2f2;
}

.modalReservationEditForm {
  /* width: 378px !important; */
}

.modalReservationEditForm .modal-content {
  border-radius: 0px;
  background-color: #f2f2f2;
}

.orangeButton {
  background-color: #fe9015;
  color: white;
  border: none;
  border-radius: 0px;
  padding: 7px 10px;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.orangeButton > svg {
  fill: #fff;
}

.orangeButton:hover {
  background-color: #e78514;
}

.orangeButton-outline {
  color: #000;
  border: solid 2px #fe9015;
  border-radius: 0px;
  padding: 5px 8px;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.orangeButton-outline > svg {
  fill: #000;
}

.orangeButton-outline:hover {
  background-color: #f7d8b6;
}

.fullWidth {
  display: flex-fluid;
  width: 100%;
}

.disabledButton {
  opacity: 0.5;
}

.disabledOption {
  color: #999;
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.2;
  border-color: #999;
  color: #999;
}

.disabledOption:hover {
  background-color: #f2f2f2;
}
.greyButton {
  background-color: #aaa;
  color: white;
  border: none;
  border-radius: 0px;
  padding: 7px 10px;
  justify-content: center;
}

.reservation {
  font-weight: 700;
}

.reservation > div {
  padding: 5px 0;
}

.dateString {
  color: #bbb;
}

.curserPointer {
  cursor: pointer;
}

.accessTimes td,
th {
  color: #bbb;
}

.statusField {
  font-weight: 400;
  color: black;
}

.datePickerWidth {
  width: 58% !important;
}

/* .selectOptions{
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  background-color: yellowgreen !important;
  border-style: solid !important;
  border-width: 4px !important;
  border: green !important;
} */

/* .select-style {
  background-color: red;
}

.select-style select {
  background-color: blueviolet;
}

.select-style:after {
  background-color: chartreuse;
} */

/* .col {
  border: solid 1px red;
}

.row {
  border: solid 1px blue;
} */

.sharepark-table {
  border-collapse: collapse;
  width: 100%;
}

.sharepark-table thead,
.sharepark-table tbody {
  background-color: white;
  font-size: 14px;
  font-weight: 400;
}

.sharepark-table tbody tr:hover {
  background-color: #f2f2f2;
}

.sharepark-table th {
  color: #fe9015;
}

.error {
  color: red;
}
